import { useTranslation } from "next-i18next";
import { bool, func, shape, string } from "prop-types";

import Icon from "~components/Icon";
import classnames from "~utils/classnames";

import BaseCard, { baseCardPropTypes } from "./BaseCard";
import classes from "./VenueCard.module.scss";

const emptyArray = [];

const VenueCard = ({
  title,
  image,
  infoRows = emptyArray,
  onAddToFavourites,
  isFavourite = false,
  isLoading = false,
  slug,
}) => {
  const { t } = useTranslation();
  const handleAddToFavouritesClick = (event) => {
    // stop event so parent anchor click is not triggered
    event.preventDefault();
    onAddToFavourites?.();
  };
  return (
    <BaseCard
      slug={slug}
      image={image}
      title={title}
      infoRows={infoRows}
      topRightComponent={
        <button
          onClick={handleAddToFavouritesClick}
          disabled={isLoading}
          className={classnames(
            classes.addButton,
            isLoading && classes.addButton__loading,
          )}
        >
          {isLoading ? (
            <span className={classes.loadingSpinner} />
          ) : (
            <Icon type={isFavourite ? "checkmark" : "plus"} />
          )}
          <span className={classes.addButton_text}>
            {isFavourite ? t("remove") : t("save")}
          </span>
        </button>
      }
    />
  );
};

VenueCard.propTypes = {
  title: string.isRequired,
  image: shape({
    src: string.isRequired,
    alt: string,
  }),
  slug: string.isRequired,
  onAddToFavourites: func,
  isFavourite: bool,
  isLoading: bool,
  infoRows: baseCardPropTypes.infoRows,
};

export default VenueCard;
