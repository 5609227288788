import { node, number, oneOf, oneOfType, shape, string } from "prop-types";

import ImageComponent from "~components/ImageComponent";
import ImagePlaceholder from "~components/ImagePlaceholder";
import { ImageType } from "~types";
import classnames from "~utils/classnames";

import classes from "./BaseImage.module.scss";

const defaultImageSize = {
  width: 330,
  height: 184,
};

const BaseImage = ({
  image,
  imageSize = defaultImageSize,
  layout,
  topRightComponent,
  topLeftComponent,
  bottomLeftComponent,
  className,
}) => {
  return (
    <div
      className={classnames(classes.image, className)}
      style={{ height: imageSize.height }}
    >
      {image ? (
        <ImageComponent
          {...image}
          alt=""
          width={imageSize.width}
          height={imageSize.height}
          layout={layout}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          hideAuthor
        />
      ) : (
        // Todo: Create placeholder image. IAMS-405
        <ImagePlaceholder height={imageSize.height} />
      )}
      {topRightComponent && (
        <div className={classes.topRightComponent}>{topRightComponent}</div>
      )}
      {topLeftComponent && (
        <div className={classes.topLeftComponent}>{topLeftComponent}</div>
      )}
      {bottomLeftComponent && (
        <div className={classes.bottomLeftComponent}>{bottomLeftComponent}</div>
      )}
    </div>
  );
};

BaseImage.propTypes = {
  image: shape(ImageType).isRequired,
  imageSize: shape({
    width: number,
    height: number,
  }),
  layout: oneOf(["fill", "responsive", undefined]),
  topRightComponent: oneOfType([string, node]),
  topLeftComponent: oneOfType([string, node]),
  bottomLeftComponent: oneOfType([string, node]),
  className: string,
};

export default BaseImage;
