import Link from "next/link";
import { arrayOf, node, oneOf, shape, string } from "prop-types";

import Icon from "~components/Icon";
import { iconTypes } from "~components/Icon/icons";
import { PreprDataType } from "~types";
import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import BaseImage from "./BaseImage";
import classes from "./Card.module.scss";

const emptyArray = [];

const BaseCard = ({
  slug,
  title,
  image,
  infoRows = emptyArray,
  children,
  bottomLeftComponent,
  topRightComponent,
  topLeftComponent,
  imageSource = "prepr",
  preprData,
  className,
}) => (
  <Link
    href={toValidUrl(`/${slug}`)}
    passHref
    className={classnames(classes.card, className)}
    prepr-personalisation={preprData?.personalizationId}
    prepr-segment={preprData?.segmentIds}
  >
    <BaseImage
      image={image}
      title={title}
      bottomLeftComponent={bottomLeftComponent}
      topRightComponent={topRightComponent}
      topLeftComponent={topLeftComponent}
      layout={imageSource === "prepr" ? undefined : "responsive"}
    />
    <h3 className={classes.title}>
      <span>{title}</span>
    </h3>
    {infoRows.length > 0 && (
      <ul className={classes.infoRows}>
        {infoRows.map(({ iconType, text, subText }) => {
          const icon = <Icon type={iconType} />;
          return (
            <li key={text}>
              {icon && <div className={classes.infoRowIcon}>{icon}</div>}
              <div className={classes.infoRowText}>
                <span>{text}</span>
                {subText && (
                  <span className={classes.infoRowSubText}>{subText}</span>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    )}
    {children}
  </Link>
);

export const baseCardPropTypes = {
  slug: string.isRequired,
  title: string.isRequired,
  image: shape({
    alt: string,
    src: string,
  }),
  infoRows: arrayOf(
    shape({
      iconType: oneOf(iconTypes),
      text: string.isRequired,
      subText: string,
    }),
  ),
  topRightComponent: node,
  bottomLeftComponent: node,
  topLeftComponent: node,
  children: node,
  imageSource: oneOf(["prepr", "feedfactory"]),
  preprData: shape(PreprDataType),
  className: string,
};

BaseCard.propTypes = baseCardPropTypes;

export default BaseCard;
