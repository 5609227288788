import { useTranslation } from "next-i18next";
import { arrayOf, bool, oneOf, shape, string } from "prop-types";
import { useContext } from "react";

import CityCardTags from "~components/Cards/CityCardTags";
import FavouriteButton from "~components/Cards/FavouriteButton";
import Chip from "~components/Chip";
import Icon from "~components/Icon";
import TagLabelsContext from "~contexts/TagLabelsContext";
import {
  AccessibilityFeaturesComponentType,
  EventUrlType,
  FreeTagsType,
  ImageType,
  PreprDataType,
} from "~types";
import classnames from "~utils/classnames";

import BaseCard from "./BaseCard";
import cardClasses from "./Card.module.scss";
import classes from "./EventCard/EventCard.module.scss";

const hasBooking = (urls) => {
  return urls?.filter(Boolean).some(({ type }) => type === "Booking");
};

const LocationCard = ({
  id,
  name,
  slug,
  images,
  freeTags,
  urls,
  soldOut,
  preprData,
  accessibilityFeatures,
}) => {
  const { getTagLabel } = useContext(TagLabelsContext);
  const tagLabel = getTagLabel(freeTags);
  const { t } = useTranslation();

  const ticketAvailable = hasBooking(urls);

  const hasMultipleAccessibilityFeatures = (accessibilityFeatures) =>
    accessibilityFeatures?.filter((feature) => feature.value).length > 1;

  return (
    <BaseCard
      image={images?.[0]}
      title={name}
      slug={slug}
      imageSource="feedfactory"
      topRightComponent={<FavouriteButton itemId={id} itemTitle={name} />}
      preprData={preprData}
    >
      {/* Tags */}
      {!!tagLabel?.length && (
        <div className={classes.tags}>
          {tagLabel
            .filter((tag) => tag?.labelTitle)
            .map((tag) => (
              <Chip key={tag.id} color="black" className={classes.tag}>
                {tag.labelTitle}
              </Chip>
            ))}
        </div>
      )}

      {/* Accessibility Info */}
      {hasMultipleAccessibilityFeatures(accessibilityFeatures) && (
        <div className={cardClasses.info}>
          <Icon type="accessibility" />
          {t("accessibilityAvailable")}
        </div>
      )}

      {/* Ticket Info */}
      {ticketAvailable && (
        <div
          className={classnames([
            cardClasses.info,
            soldOut && cardClasses.soldout,
          ])}
        >
          <Icon type="ticket" />
          {soldOut ? t("ticketsSoldOut") : t("ticketsAvailable")}
        </div>
      )}

      {/* Free Tags */}
      {freeTags && <CityCardTags freeTags={freeTags} />}
    </BaseCard>
  );
};

export const LocationCardType = {
  id: string.isRequired,
  type: oneOf(["Location"]).isRequired,
  name: string.isRequired,
  slug: string,
  images: arrayOf(shape(ImageType)),
  freeTags: FreeTagsType,
  urls: arrayOf(shape(EventUrlType)),
  preprData: shape(PreprDataType),
  soldOut: bool,
  accessibilityFeatures: AccessibilityFeaturesComponentType,
};

LocationCard.propTypes = LocationCardType;

export const LocationCardFragment = /* GraphQL */ `
  fragment LocationCardFragment on Location {
    id: _id
    type: __typename
    name
    slug: _slug
    images {
      ...ImageFragment
    }
    freeTags: free_tags {
      body
    }
    accessibilityFeatures: accessibility_features {
      ...AccessibilityFeatureFragment
    }
    urls {
      ... on Website {
        type: __typename
        id: _id
        url
      }
      ... on TimeSlots {
        type: __typename
        id: _id
        url
      }
      ... on Booking {
        type: __typename
        id: _id
        url
      }
      ... on SocialMedia {
        type: __typename
        id: _id
        url
      }
      ... on LastMinute {
        type: __typename
        id: _id
        url
      }
    }
    soldOut: sold_out
  }
`;

export default LocationCard;
